import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SIGABRT } from 'node:constants';
import {LoginComponent} from './core/Login/login.component';
import {SignUpComponent} from './core/sign-up/sign-up.component';
import {DashboardComponent} from './core/dashboard/dashboard.component';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
const routes: Routes = [
  {path: '', pathMatch:'full', redirectTo:'login'},
  {path: 'login', component:LoginComponent },
  {path: 'sign-up', component:SignUpComponent},
  {path: 'home', component:DashboardComponent},

];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
