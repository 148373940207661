import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import Amplify, { Auth } from 'aws-amplify';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms'; 
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { LoginComponent } from './core/Login/login.component';
import { SignUpComponent } from './core/sign-up/sign-up.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import {MatIconModule} from '@angular/material/icon';




Amplify.configure({
  Auth:{
    mandatorySignIn:true,
    region: 'us-east-1',
    userPoolId: 'us-east-1_HkX5v5uaY',
    userPoolWebClientId: '31goilt5aaqpbo84acs1abfket',
    authenticationFlowType:'USER_PASSWORD_AUTH'
  }

});
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    SignUpComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }